import { SdsFiltersAndIncludes } from '@/interfaces/api/general/company';
import { Sds } from '@/interfaces/model/general/sds';
import { StatusId } from '@/lib/enum';
import store from '@/store';
import {
  dispatchExportSdses,
  dispatchGetCompanyExportOperations,
} from '@/store/main/actionsMain';
import { readCompanyId } from '@/store/main/getters';
import { AxiosResponse } from 'axios';
import { ref } from 'vue';

export type ExportSdsesTarget = 'all' | 'selected';
export type ExportSdsesType = 'normal' | 'custom';
export type ExportSdsesCharacterCode = 'utf-8' | 'shift-jis';

type ExportOperation = {
  name: string;
  formats?: string[];
  selectable_character_code: boolean;
};
export type ExportOperations = {
  normal: ExportOperation[];
  custom?: ExportOperation[];
};

export const useSdsesExport = () => {
  const exportOperations = ref<ExportOperations>({
    normal: [{ name: 'Excel', selectable_character_code: false }],
  });
  const exportSdsesTarget = ref<ExportSdsesTarget>('all'); // エクスポート対象
  const exportSdsesType = ref<ExportSdsesType>('normal'); // エクスポート種別
  const exportFileType = ref<string>(); //ファイル形式
  const exportSdsesFormat = ref<string>(); // エクスポート形式
  const selectableCharacterCode = ref(false); // 文字コードを選べるかどうか
  const exportSdsesCharacterCode = ref<ExportSdsesCharacterCode>('shift-jis'); // エクスポート文字コード

  // モーダルの設定情報を取得する
  const fetchCompanyExportOperations = async (companyId: string) => {
    const response: AxiosResponse = await dispatchGetCompanyExportOperations(
      store,
      {
        companyId,
      }
    );
    if (response?.data) {
      exportOperations.value = response.data;
    }
  };

  const exportSdses = async (
    selectedSdses: Sds[],
    params: SdsFiltersAndIncludes,
    is_selected_whole_sdses?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<AxiosResponse<any, any>> => {
    if (!exportFileType.value) {
      // MEMO: 事前にvalidateExportSdsesを必ず呼ぶので実際は到達しない
      throw new Error('ファイル形式を指定してください');
    }
    const selectedSdsesUuid = selectedSdses.map((sds) => sds.uuid);
    const is_whole_sdses = exportSdsesTarget.value === 'all' ? true : false;

    return await dispatchExportSdses(store, {
      companyId: readCompanyId(store),
      data: {
        ...params,
        is_selected_whole_sdses,
        is_whole_sdses: is_whole_sdses,
        export_type: exportSdsesType.value,
        file_format: exportFileType.value,
        export_format: exportSdsesFormat.value,
        character_code: exportSdsesCharacterCode.value,
        sds_uuid_list:
          exportSdsesTarget.value === 'selected'
            ? selectedSdsesUuid
            : undefined,
        status_id_list: [StatusId.SECOND_CHECK_DONE.value],
      },
    });
  };

  const resetExportSdsesForm = () => {
    exportSdsesTarget.value = 'all';
    exportSdsesType.value = 'normal';
    exportFileType.value = undefined;
    exportSdsesFormat.value = undefined;
    selectableCharacterCode.value = false;
    exportSdsesCharacterCode.value = 'shift-jis';
  };

  const validateExportSdses = (): string | null => {
    if (!exportSdsesTarget.value) {
      return 'エクスポート対象を指定してください';
    }
    if (!exportSdsesType.value) {
      return 'エクスポート種別を指定してください';
    }
    if (!exportFileType.value) {
      return 'ファイル形式を指定してください';
    }
    if (
      exportFileType.value !== 'Excel' &&
      exportFileType.value !== '標準CREATE SIMPLE 製品DB CSV' &&
      !exportSdsesFormat.value
    ) {
      return 'エクスポート形式を指定してください';
    }
    if (exportFileType.value !== 'Excel' && !exportSdsesCharacterCode.value) {
      return 'エクスポート文字コードを指定してください';
    }
    return null;
  };

  return {
    exportOperations,
    exportSdsesTarget,
    exportSdsesType,
    exportFileType,
    exportSdsesFormat,
    selectableCharacterCode,
    exportSdsesCharacterCode,
    fetchCompanyExportOperations,
    resetExportSdsesForm,
    validateExportSdses,
    exportSdses,
  };
};
